<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Laboran</b></div>
      <b-button
        squared
        variant="primary"
        @click="$router.push('/masters/users/laboran/add')"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-4">
                  <b-input-group>
                    <template #append>
                      <b-button
                        variant="success"
                        squared
                        size="sm"
                        @click="search"
                      >
                        Cari
                      </b-button>
                      <b-button
                        variant="danger"
                        squared
                        size="sm"
                        @click="reset"
                      >
                        Reset
                      </b-button>
                    </template>
                    <b-form-input
                      type="text"
                      v-model="filter.name"
                      placeholder="Cari Nama"
                    ></b-form-input>
                  </b-input-group>
                </div>
            </div>
            <!-- table -->
            <b-table
              striped
              hover
              :items="items"
              :fields="fields"
              responsive
              class="mt-3"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width: field.key === 'action' ? '25%' : '',
                  }"
                />
              </template>

              <template #cell(photo)="data">
                <div
                  class="
                    d-flex
                    justify-content-start
                    align-items-center
                    image-container
                  "
                >
                  <div class="image-input-wrapper" style="position: relative">
                    <img class="image" :src="data.item.photo" />
                  </div>
                </div>
              </template>

              <template #cell(action)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-circle btn-outline-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({
                      path:
                        '/masters/users/laboran/detail/' + data.item.id,
                    })
                  "
                  ><i class="fas fa-eye px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-circle btn-outline-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="
                    $router.push({
                      path:
                        '/masters/users/laboran/edit/' + data.item.id,
                    })
                  "
                  v-if="btnAccess"
                  ><i class="fas fa-edit px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="btn-circle btn-outline-danger mr-1"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.id)"
                  v-if="btnAccess"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="btn-circle"
                  v-b-tooltip.hover
                  title="Reset Password"
                  @click="resetPassword(data.item.id)"
                  v-if="btnAccess"
                >
                  <i class="fas fa-redo px-0"></i>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import Table from "@/view/components/users/Table.vue";
import ApiService from "@/core/services/api.service.js";

export default {
  name: "master-users",
  components: {
    Table,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        role_id: 3,
        major_id: "",
        school_year_id: "",
        class_group_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "registration_number",
          label: "NIP",
          sortable: true,
        },
        {
          key: "phone",
          label: "Telepon",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: 'nowrap'
        },
      ],
      items: [],
      // access
      btnAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&role_id=${this.filter.role_id}&major_id=${this.filter.major_id}&school_year_id=${this.filter.school_year_id}&class_group_id=${this.filter.class_group_id}`;
      let response = await module.paginate(
        "api/users",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/users/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    resetPassword(userId) {
      Swal.fire({
        title: "Reset Password ?",
        text: "",
        icon: "question",
        showCancelButton: true,
      }).then(async function (result) {
        if (result.value) {
          // SwalLoading
          SwalLoading.fire();
          ApiService.post(`api/users/reset-password/${userId}`)
            .then((response) => {
              console.log(response);
              // Swal.fire({
              //   title: "Berhasil reset password",
              //   text: `Password barunya adalah ${response.data.item.original.newPassword}`,
              //   icon: "success",
              //   allowEscapeKey: false,
              //   allowOutsideClick: false,
              // }).then((result) => {
              //   console.log("result", result);
              //   // if (redirect) {
              //   //   result.value ? (document.location.href = redirect) : false
              //   // }
              // });

              Swal.fire({
                title: "Berhasil reset password",
                text: `Password barunya adalah ${response.data.item.original.newPassword}`,
                icon: "success",
                showCloseButton: true,
                showCancelButton: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                focusConfirm: false,
                confirmButtonText: 'Oke',
                cancelButtonText: "Oke dan kirim WhatsApp",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#10c546",
              }).then(result =>{
                console.log("result", result);
                if(!result.isConfirmed){
                  window.open('https://api.whatsapp.com/send?phone=62' + response.data.item.original.phone.substr(1) +'&text=Halo%20*' + response.data.item.original.name + '*%0ABerikut%20password%20baru%20akun%20Anda%20%3A%20' + response.data.item.original.newPassword, '_blank');
                }
              })
              // Swal.fire(response.success.title, response.success.message, 'success')
              Swal.fire({
                title: response.data.title,
                text: response.data.message,
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((error) => {
              console.log("error", error);
              SwalLoading.close();
              Swal.fire({
                title: "Gagal",
                text: "Gagal reset password",
                icon: "error",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laboran", route: "/masters/users/laboran" },
    ]);
    this.checkAccess();
    this.pagination();
  },
};
</script>
